@font-face {
  font-family: Mulish;
  src: url("Mulish-Italic.b8702c75.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Mulish;
  src: url("Mulish-ExtraLight.bca5a3a0.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Mulish;
  src: url("Mulish-Light.437a5e09.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Mulish;
  src: url("Mulish-Regular.5d9997f5.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Mulish;
  src: url("Mulish-SemiBold.5f51d8d1.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Mulish;
  src: url("Mulish-SemiBold.5f51d8d1.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Mulish;
  src: url("Mulish-Bold.f9325e4e.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Mulish;
  src: url("Mulish-ExtraBold.b3a0dd6f.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Italic.033195aa.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-ExtraLight.735f0c10.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Light.bf47dc5c.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Regular.d02a2749.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Medium.d3bda845.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-SemiBold.b82f9c68.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-SemiBold.b82f9c68.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Bold.c50a5f9a.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-ExtraBold.54714f7a.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/*# sourceMappingURL=index.a55b6f2a.css.map */
